import React, {useEffect, useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import Anchor from 'react-bootstrap/Anchor';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Projects.css'

function Projects() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEmailClick = (e, url) => {
        e.preventDefault();
        window.open(url, '_blank');
    };

    return (
        <>
            <Anchor onClick={handleShow}>
                <span className="span-text">Dev Projects (HTML Email, etc)</span>
            </Anchor>

            <Modal show={show} onHide={handleClose} backdrop={true} size="md">
                <Modal.Header closeButton>
                <Modal.Title>Dev Projects</Modal.Title>
                </Modal.Header>
                <Modal.Body modal-body=''>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md={9}>

                <Carousel slide={false} interval={null} variant="dark">
                    <Carousel.Item>
                        <a href="#" onClick={(e)=>handleEmailClick(e, './emails/am-index.html')}>
                            <img
                            className="d-block w-100"
                            src="https://i.ibb.co/PtCdX6D/allmusic-thumbnail.png"
                            alt="allmusic"
                            name="allmusic"
                            />
                        </a>
                        <Carousel.Caption>
                            <div className="caption-group">
                                <h5>Allmusic Promotional HTML Email</h5>
                                <p>Concept for a promotional email from the music reference website
                                <br/>
                                <i>HTML/CSS</i>
                                </p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="#" onClick={(e)=>handleEmailClick(e, './emails/ln-index.html')}>
                            <img
                                className="d-block w-100"
                                src="https://i.ibb.co/kxzHQYG/bank-confirmation-thumbnail.png"
                                alt="lenix"
                                name="lenix"
                            />
                        </a>
                        <Carousel.Caption>
                            <div className="caption-group">
                                <h5>Bank Transfer Notification</h5>
                                <p>Fictional "Lenix Bank", Bitcoin transfer<br/>
                                <i>HTML/CSS</i></p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="https://i.ibb.co/dPKQnMB/job-crawler.png"
                            alt="job-crawler"
                            name="job-crawler"
                        />
                        <Carousel.Caption>
                            <div className="caption-group">
                                <h5>"Job Crawler"</h5>
                                <p>
                                    System for tracking job applications <br/>LINK NOT CURRENTLY AVAILABLE <br />
                                    <i>ASP.NET Core MVC, jQuery, DataTables, Bootstrap</i>
                                </p>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Projects;