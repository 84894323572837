import './App.css';
import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import ContactMe from './pages/ContactMe';
import Navbar from './components/Navbar';
import BioModal from './components/BioModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const [modalOpen, setModalOpen] = useState(false);
  const [cmModalOpen, setCmModalOpen] = useState(false);
  const [projModalOpen, setProjModalOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const currYr = new Date().getFullYear();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const iconsResponsive = windowWidth > 615;

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }

  const toggleCmModal = () => {
    setCmModalOpen(!cmModalOpen);
  }

  const toggleProjModal = () => {
    setProjModalOpen(!projModalOpen);
  };

  const handleProjClose = () => {
    setProjModalOpen(false);
  };

  const handleProjOpen = () => {
    setProjModalOpen(true);
  };

  const toggleToastOn = () => {
    setToastOpen(true);
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 615 && modalOpen) {
        setModalOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [modalOpen]);

  return (
    <div className="App">
      <ToastContainer position='top-center'/> {/*Did this one do it or did the one in contactme.js?*/}
      {modalOpen && <BioModal closeModal={toggleModal} />}
      {cmModalOpen && <ContactMe cmCloseModal={toggleCmModal} toastOpen={toggleToastOn} />}
      <div className='background-container'>
        <div className='background-image'>
            <div className='header-section'>
              <h1 className='title-h1'>.:Joseph Hooker:Email + Web Dev, {currYr}-ready🦺:.</h1>
            </div>
            <div className='body-section container-fluid'>
            {!iconsResponsive && <div className='img-and-icons'>
                <div className='icons'>
                    <a href='https://github.com/jchooker'><img src='https://i.ibb.co/MfhVgRH/gh-white.png' className='icon' alt='gh-icon' title='gh-icon'></img></a>
                    <a href='https://stackoverflow.com/users/16783168/jhookerdev'><img src='https://i.ibb.co/rdCrdRT/so-white.png' className='icon' alt='so-icon' title='so-icon'></img></a>
                    <a href='https://www.linkedin.com/in/joseph-hooker-223528a9/'><img src='https://i.ibb.co/9tfD4Qg/li-white-logo.png' className='icon' alt='li-icon' title='li-icon'></img></a>
                    <a href='https://twitter.com/jhookerdev'><img src='https://i.ibb.co/yWZ4YzZ/tw-white-logo.png' className='icon' alt='tw-icon' title='tw-icon'></img></a>
                  </div> 
                <img src='https://i.ibb.co/3dSw7pp/self1.jpg' className='self-img' alt='self-img' title='self-img'></img>
              </div>}     
              {iconsResponsive && <div className='img-and-icons'>
                <img src='https://i.ibb.co/3dSw7pp/self1.jpg' className='self-img' alt='self-img' title='self-img'></img>
                <div className='icons'>
                    <a href='https://github.com/jchooker'><img src='https://i.ibb.co/MfhVgRH/gh-white.png' className='icon' alt='gh-icon' title='gh-icon'></img></a>
                    <a href='https://stackoverflow.com/users/16783168/jhookerdev'><img src='https://i.ibb.co/rdCrdRT/so-white.png' className='icon' alt='so-icon' title='so-icon'></img></a>
                    <a href='https://www.linkedin.com/in/joseph-hooker-223528a9/'><img src='https://i.ibb.co/9tfD4Qg/li-white-logo.png' className='icon' alt='li-icon' title='li-icon'></img></a>
                    <a href='https://twitter.com/jhookerdev'><img src='https://i.ibb.co/yWZ4YzZ/tw-white-logo.png' className='icon' alt='tw-icon' title='tw-icon'></img></a>
                </div> 
              </div>}                                    
              <div className='router-container'> 
                <Router>
                  <Navbar toggleModal={toggleModal} toggleCmModal={toggleCmModal} toggleProjModal={handleProjOpen}>
                  </Navbar>
                </Router>
              </div>
          </div>
          <div className='footer-section'>
            <p>© {currYr} Joseph Hooker</p>
          </div>
          </div>
      </div>
    </div>
  );
}

export default App;
