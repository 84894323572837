import React from 'react';
import './css/BioModal.css';
import BioParagraph from "./BioParagraph";
import youngMe from '../assets/young-me.jpg';

export default function BioModal({closeModal}) {

    return (
        <div className='bio-overlay'>
            <div className='bio-modal'>

                    <div className='bio-modal-header'>
                        <h2>About Me</h2>
                        <button className='close-bio-modal' onClick={closeModal}>X</button>
                    </div>
                    <div className='bio-modal-body'>
                        <BioParagraph />
                        <div className='young-me-div'>
                            <img className='young-me' src={youngMe}></img>
                            <figcaption>I do whatever face this is 10% less now. Credit: Beverly Hooker</figcaption>
                        </div>
                    </div>

            </div>
        </div>
        );
    }
