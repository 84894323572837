import React, {useState, useEffect} from "react";
import "./css/Navbar.css";
import bioText from "./bioText";
import resume from "../assets/July.2023.C.IT.pdf";
import Projects from "../pages/Projects";

function Navbar({toggleModal, toggleCmModal, toggleProjModal}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); //needed to make sure that a resize will make the About Me
                                                                    //section appear in the correct spot

    const projStyle = {textShadow: `(0 0 3px #fff, 0 0 5px #fff, 0 0 10px #fff, 0 0 21px #0fa, 0 0 41px #0fa, 0 0 46px #0fa, 0 0 51px #0fa, 0 0 75px #0fa)`};
    
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const shouldShowAboutMe = windowWidth > 615;

    const handleAboutMeClick = () => { 
        if(!shouldShowAboutMe) {
            toggleModal();
        }
    }

    const handleCmClick = () => { 
        toggleCmModal();
    }

    const handleProjClick = () => {
        toggleProjModal();
    }; 

    return (
        <div className="navbar">
            <div className="links">
                <ul className="link-ul">
                    <li><a href={resume} target="_blank" className="link1">Résumé (PDF)</a></li>
                    <li><div className="proj-wrapper"><Projects style={projStyle}></Projects></div></li>
                    <li><a to="/contactme" className="link3" onClick={handleCmClick}>Contact Me</a></li>
                    {shouldShowAboutMe ? null : (<li><a className="link4" onClick={handleAboutMeClick}>About Me</a></li>)}

                </ul>
                {shouldShowAboutMe ? (<p className="about-me-text"><span className="about-me-alt">About Me ⤵ </span><br></br>{bioText}</p>) : null}
                </div>
        </div>
    );
}

export default Navbar;